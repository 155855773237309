import request from '@/utils/request'

export function create(data) {
  return request({
    url: '/Notice/Create',
    method: 'post',
    data
  })
}

export function getAll(data) {
  return request({
    url: '/Notice/GetPagedList',
    method: 'post',
    data
  })
}

export function getDetail(data) {
  return request({
    url: '/Notice/Get',
    method: 'post',
    data
  })
}

export function update(data) {
  return request({
    url: '/Notice/Update',
    method: 'post',
    data
  })
}

export function remove(data) {
  return request({
    url: '/Notice/Delete',
    method: 'post',
    data
  })
}

export function removeMuliple(data) {
  return request({
    url: '/Notice/Deletes',
    method: 'post',
    data
  })
}

